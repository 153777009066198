.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid black;
  border-top: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #4f8376;
  color: white;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 20ms ease;
}
